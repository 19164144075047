<template>
  <div data-app>
    <div class="row">
      <div class="col-md-12">
        <v-card class="rounded-0">
          <div class="p-0">
            <v-form ref="form" v-model="valid" lazy-validation>
              <div class="col-md-12 p-0">
                <div class="bg-white">
                  <div class="py-4 px-4 border_b_silver col-12 ch_hight">
                    <span class="card-label font-weight-bolder Main_Blue"
                      >Post a new job</span
                    >
                  </div>
                  <div class="col-12 row m-0">
                    <!-- job title -->
                    <div class="col-md-3 pl-0 border__input_ch pt-0 pb-0">
                      <v-text-field
                        v-model="title"
                        :rules="titleRules"
                        label="Job Title*"
                        required
                      ></v-text-field>
                    </div>
                    <!-- Minimum qualification -->
                    <div class="col-md-3 pl-0 border__input_ch pt-0 pb-0">
                      <v-combobox
                        v-model="education"
                        :items="educationOptions"
                        size="sm"
                        class="border__input_ch"
                        label="Minimum qualification*"
                        chips
                      >
                        <template>
                          <v-chip
                            :key="JSON.stringify(data.item)"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            :disabled="data.disabled"
                            @click:close="data.parent.selectItem(data.item)"
                          >
                            {{ data.item }}
                          </v-chip>
                        </template>
                      </v-combobox>
                    </div>
                    <!-- degree Subjects -->
                    <div class="col-md-2 pr-0 border__input_ch pt-0 pb-0 pl-0">
                      <v-combobox
                        v-model="degreeSubject"
                        :items="degreeSubjectOptions"
                        size="sm"
                        class="border__input_ch"
                        label="Preferred qualification"
                        chips
                        multiple
                      >
                        <template>
                          <v-chip
                            :key="JSON.stringify(data.item)"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            :disabled="data.disabled"
                            @click:close="data.parent.selectItem(data.item)"
                          >
                            {{ data.item }}
                          </v-chip>
                        </template>
                      </v-combobox>
                    </div>
                    <!-- Minumem Experience -->
                    <div class="col-md-2 border__input_ch pt-0 pb-0">
                      <v-text-field
                        v-model="minimumExperience"
                        type="number"
                        label="Minimum experience"
                        required
                      ></v-text-field>
                    </div>
                    <!-- Gender -->
                    <div class="col-md-2 border__input_ch pt-0 pb-0">
                      <v-select
                        v-model="genders"
                        :items="gendersOptions"
                        attach
                        chips
                        label="Gender"
                        multiple
                      ></v-select>
                    </div>

                    <!-- number of vacancies -->
                    <div class="col-md-3 border__input_ch pl-0 pt-0 pb-0">
                      <v-text-field
                        v-model="noOfVacancies"
                        type="number"
                        label="No. of vacancies*"
                        required
                      ></v-text-field>
                    </div>
                    <!-- apply by -->
                    <div class="col-md-3 border__input_ch pt-0 pb-0 pl-0">
                      <v-text-field
                        v-model="applyBy"
                        label="Apply by"
                        type="date"
                      ></v-text-field>
                    </div>
                    <!-- cities -->
                    <div class="col-md-3 border__input_ch pt-0 pb-0 pl-0">
                      <v-select
                        v-model="city"
                        :items="cityOptions"
                        attach
                        chips
                        label="Cities*"
                        multiple
                      ></v-select>
                      <!-- <v-combobox
                        v-model="city"
                        :items="cityOptions"
                        size="sm"
                        class="border__input_ch"
                        label="Cities*"
                        chips
                        multiple
                      >
                        <template>
                          <v-chip
                            :key="JSON.stringify(data.item)"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            :disabled="data.disabled"
                            @click:close="data.parent.selectItem(data.item)"
                          >
                            {{ data.item.text }}
                          </v-chip>
                        </template>
                      </v-combobox> -->
                    </div>

                    <!-- delete job after expery -->
                    <div class="col-3 p-0 m-0">
                      <v-checkbox
                        class="checkbox_ch"
                        v-model="delete_after_expiry"
                        label="delete after expiry?"
                        required
                      ></v-checkbox>
                    </div>
                    <!-- delete job after expery -->

                    <!-- </div> -->
                    <!-- job sector -->
                    <!-- <div class="col-md-3 pr-0 border__input_ch pt-0 pb-0">
                      <v-combobox
                        v-model="industries"
                        :items="industriesOptions"
                        label="Job Sector"
                        size="sm"
                        class="border__input_ch"
                        chips
                      >
                        <template>
                          <v-chip
                            :key="JSON.stringify(data.item)"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            :disabled="data.disabled"
                            @click:close="data.parent.selectItem(data.item)"
                          >
                            {{ data.item }}
                          </v-chip>
                        </template>
                      </v-combobox>
                    </div> -->

                    <!-- JOB industry -->
                    <!-- <div class="col-md-3" style="margin-top: 3%">
                          <b-form-select
                            v-model="industry"
                            :options="industriesOptions"
                            size="sm"
                            class="mt-3"
                          ></b-form-select>
                        </div> -->
                  </div>
                  <!--code  -->
                  <div class="col-12 row p-0 m-0" style="background: #eef0f8">
                    <div class="bg-white mt-4 p-0">
                      <div class="col-12 p-0">
                        <div class="py-4 px-4 border_b_silver col-12 ch_hight">
                          <span class="card-label font-weight-bolder Main_Blue"
                            >Required skills</span
                          >
                        </div>
                      </div>
                      <div class="col-12 py-4">
                        <div
                          class="row"
                          v-for="(item, index) in educationSkill"
                          :key="index"
                        >
                          <!-- JOB Function -->
                          <div class="col-md-5 pr-0 border__input_ch pt-0 pb-0">
                            <v-combobox
                              v-model="item.jobFunction"
                              :items="jobFunctionsOptions"
                              label="Job Function*"
                              size="sm"
                              class="border__input_ch"
                              chips
                              @change="changeSkill(item.jobFunction)"
                            >
                              <template>
                                <v-chip
                                  :key="JSON.stringify(data.item)"
                                  v-bind="data.attrs"
                                  :input-value="data.selected"
                                  :disabled="data.disabled"
                                  @click:close="
                                    data.parent.selectItem(data.item)
                                  "
                                >
                                  {{ data.item }}
                                </v-chip>
                              </template>
                            </v-combobox>
                          </div>
                          <!-- Required skill -->
                          <div class="col-md-5 pr-0 border__input_ch pt-0 pb-0">
                            <!-- <v-select
                              v-model="item.skill"
                              :items="skillOptions"
                              attach
                              chips
                              label="Required Skills*"
                              multiple
                            ></v-select> -->
                            <v-combobox
                              v-model="item.skill"
                              :items="skillOptions"
                              label="Required Skills*"
                              multiple
                              class="border__input_ch"
                            >
                              <template v-slot:selection="data">
                                <v-chip
                                  :key="JSON.stringify(data.item)"
                                  v-bind="data.attrs"
                                  :input-value="data.selected"
                                  :disabled="data.disabled"
                                  @click:close="
                                    data.parent.selectItem(data.item)
                                  "
                                >
                                  {{ data.item.skill_name }}
                                </v-chip>
                              </template>
                            </v-combobox>
                          </div>

                          <!-- button -->
                          <div
                            class="col-md-2"
                            style="
                              display: flex;
                              margin-top: 0.5%;
                              margin-left: 0%;
                            "
                          >
                            <v-btn
                              class="custom-add-new-record-button btn_row_ch"
                              type="button"
                              @click="addNewRow()"
                              >Add</v-btn
                            >
                            <v-btn
                              v-if="index > 0"
                              class="custom-delete-new-record-button btn_row_ch"
                              type="button"
                              @click="deleteRow(index)"
                              >Del</v-btn
                            >
                          </div>
                          <!-- button -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- code -->
                  <div class="col-12 row p-0 m-0" style="background: #eef0f8">
                    <div class="bg-white mt-4 p-0 mb-4">
                      <div class="col-12 p-0">
                        <div class="py-4 px-4 border_b_silver col-12 ch_hight">
                          <span class="card-label font-weight-bolder Main_Blue"
                            >Nature of the job</span
                          >
                        </div>
                      </div>
                      <div class="col-12 py-4 row m-0">
                        <!-- job condition -->
                        <div class="col-3">
                          <v-checkbox
                            class="p-0 m-0 checkbox_ch"
                            v-model="featured"
                            label="Featured"
                            required
                          ></v-checkbox>
                        </div>
                        <div class="col-3">
                          <v-checkbox
                            class="p-0 m-0 checkbox_ch"
                            v-model="spotlight"
                            label="Spotlight"
                            required
                          ></v-checkbox>
                        </div>
                        <div class="col-3">
                          <v-checkbox
                            class="p-0 m-0 checkbox_ch"
                            v-model="urgent"
                            label="Urgent"
                            required
                          ></v-checkbox>
                        </div>
                        <div class="col-12 row p-0 m-0">
                          <!-- JOB SHFIT -->
                          <div class="col-md-4 px-0 border__input_ch pt-0 pb-0">
                            <v-select
                              v-model="experience"
                              :items="experienceOptions"
                              attach
                              chips
                              label="Career Level*"
                              multiple
                            ></v-select>
                          </div>
                          <div class="col-md-4 pr-0 border__input_ch pt-0 pb-0">
                            <!-- Career Level -->
                            <v-select
                              v-model="jobShift"
                              :items="jobShiftsOptions"
                              attach
                              chips
                              label="Job shift*"
                              multiple
                            ></v-select>
                          </div>
                          <!-- job type -->
                          <div class="col-md-4 pr-0 border__input_ch pt-0 pb-0">
                            <v-select
                              v-model="jobType"
                              :items="jobTypesOptions"
                              attach
                              chips
                              label="Job Type*"
                              multiple
                            ></v-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- sellery -->
                  <div class="col-12 row p-0 m-0" style="background: #eef0f8">
                    <div class="bg-white mt-0 p-0 mb-4">
                      <div class="col-12 p-0">
                        <div class="py-4 px-4 border_b_silver col-12 ch_hight">
                          <span class="card-label font-weight-bolder Main_Blue"
                            >Compensation & benefits</span
                          >
                        </div>
                      </div>
                      <div class="col-12 py-0 row m-0">
                        <div class="col-4 px-0 border__input_ch pt-0 pb-0">
                          <v-text-field
                            v-model="min_salary"
                            :rules="salaryRules"
                            label="Min Salary*"
                            required
                          ></v-text-field>
                        </div>
                        <div class="col-4 pr-0 border__input_ch pt-0 pb-0">
                          <v-text-field
                            v-model="max_salary"
                            :rules="salaryRules"
                            label="Max Salary*"
                            required
                          ></v-text-field>
                        </div>
                        <div class="col-4 pr-0 border__input_ch pt-0 pb-0">
                          <v-select
                            v-model="benefits"
                            :items="benefitsOptions"
                            attach
                            chips
                            label="Benifits"
                            multiple
                          ></v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Questions -->
                  <div class="col-12 row p-0 m-0" style="background: #eef0f8">
                    <div class="bg-white mt-0 p-0 mb-4">
                      <div class="col-12 p-0">
                        <div class="py-4 px-4 border_b_silver col-12 ch_hight">
                          <span class="card-label font-weight-bolder Main_Blue"
                            >Questions</span
                          >
                        </div>
                      </div>

                      <div class="col-12 py-0 row m-0">
                        <div
                          class="row"
                          :style="
                            index == 0 ? 'margin-top: 0%; ' : 'margin-top: 1%; '
                          "
                          v-for="(item, index) in qa"
                          :key="index"
                        >
                          <div class="col-md-3">
                            <!-- <label>Question</label> -->
                            <v-text-field
                              v-model="item.question"
                              label="Enter question"
                              class="border__input_ch"
                            ></v-text-field>
                          </div>
                          <!-- <div class="col-md-3" style="margin-top: 1%">
                            <b-form-select
                              v-model="item.type"
                              :options="qaTypes"
                              size="sm"
                              class="mt-3"
                            ></b-form-select>
                          </div> -->
                          <div
                            class="
                              col-md-3
                              pl-0
                              border__input_ch__
                              mt-4
                              pt-0
                              pb-0
                            "
                          >
                            <!-- <v-combobox
                              v-model="item.type"
                              :items="qaTypes"
                              size="sm"
                              class="border__input_ch"
                              label="Answer type"
                            >
                            </v-combobox> -->
                            <b-form-select
                              v-model="item.type"
                              :options="qaTypes"
                              size="sm"
                              class="mt-4 border__input_ch"
                            ></b-form-select>
                          </div>
                          <div class="col-md-3" style="margin-top: -1.5%">
                            <v-container fluid>
                              <v-combobox
                                class="border__input_ch checkbox_ch"
                                v-if="item.type == 'multi'"
                                v-model="item.answer"
                                hide-selected
                                hint="Maximum of 5 tags"
                                label="Options"
                                multiple
                                persistent-hint
                                small-chips
                                :search-input.sync="search"
                              >
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        Double click to edit & press
                                        <kbd>enter</kbd> to create a new one
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-combobox>
                            </v-container>
                            <v-checkbox
                              class="checkbox_ch"
                              v-model="item.mandatory"
                              label="Mandatory?"
                              required
                            ></v-checkbox>
                          </div>
                          <div class="col-md-3" style="margin-top: 0%">
                            <div
                              class="col-md-2"
                              style="
                                display: flex;
                                margin-top: 0.5%;
                                margin-left: 0%;
                              "
                            >
                              <v-btn
                                class="custom-add-new-record-button btn_row_ch"
                                type="button"
                                fab
                                small
                                @click="addNewQuestionRow"
                                >Add</v-btn
                              >
                              <v-btn
                                v-if="index > 0"
                                class="
                                  custom-delete-new-record-button
                                  btn_row_ch
                                "
                                type="button"
                                fab
                                small
                                @click="removeQuestionRow(index)"
                                >Del</v-btn
                              >
                            </div>
                            <!-- <v-btn
                              class="
                                btn btn-sm btn-success
                                font-weight-bold
                                py-2
                                px-3 px-xxl-5
                                my-1
                              "
                              fab
                              small
                              @click="addNewQuestionRow"
                            >
                              <v-icon dark>mdi-plus</v-icon>
                            </v-btn>
                            <v-btn
                              v-if="index > 0"
                              class="
                                btn btn-sm btn-danger
                                font-weight-bold
                                py-2
                                px-3 px-xxl-5
                                my-1
                              "
                              fab
                              small
                              @click="removeQuestionRow(index)"
                            >
                              <v-icon dark>mdi-minus</v-icon>
                            </v-btn> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- scen row -->
                  <!-- <div class="col-12 row m-0 p-0"> -->
                  <!-- <div class="col-md-3 pr-0 border__input_ch pt-0 pb-0"> -->
                  <!-- <v-combobox
                        v-model="education"
                        :items="educationOptions"
                        label="Degree subject"
                        size="sm"
                        class="border__input_ch"
                        chips
                      >
                        <template>
                          <v-chip
                            :key="JSON.stringify(data.item)"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            :disabled="data.disabled"
                            @click:close="data.parent.selectItem(data.item)"
                          >
                            {{ data.item }}
                          </v-chip>
                        </template>
                      </v-combobox> -->
                  <!-- min salary -->
                  <!-- <div> -->
                  <!-- here we can add min salary -->
                  <!-- <v-select
                        v-model="salary_range"
                        :items="desiredSalaryOptions"
                        attach
                        chips
                        label="Salary Range"
                      ></v-select> -->
                  <!-- <v-text-field
                          v-model="min_salary"
                          :rules="salaryRules"
                          label="Min Salary"
                          required
                        ></v-text-field>
                      </div> -->
                  <!-- </div>
                    <div class="col-md-3 pr-0 border__input_ch pt-0 pb-0"> -->
                  <!-- max salary -->
                  <!-- <div> -->
                  <!-- here we can add max salary -->
                  <!-- <b-form-select
                    v-model="maximumSalary"
                    :options="maximumSalariesOptions"
                    size="sm"
                    class="mt-3"
                    :rules="titleRules"
                    required
                  ></b-form-select> -->
                  <!-- <v-text-field
                          v-model="max_salary"
                          :rules="salaryRules"
                          label="Max Salary"
                          required
                        ></v-text-field>
                      </div> -->
                  <!-- </div>
                    <div class="col-md-3 pr-0 border__input_ch pt-0 pb-0"> -->
                  <!-- benifits -->
                  <!-- CODE -->
                  <!-- Career Level -->
                  <!-- <div class="">
                        <v-select
                          v-model="benefits"
                          :items="benefitsOptions"
                          attach
                          chips
                          label="Benifits"
                          multiple
                        ></v-select>
                      </div> -->
                  <!-- <div class="col-md-3">
                        <b-form-select
                          v-model="benefits"
                          :options="benefitsOptions"
                          size="sm"
                          class="mt-3"
                        ></b-form-select>
                       </div> -->
                  <!-- /CODE -->
                  <!-- </div> -->
                  <!-- </div> -->
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-md-3">
                  <v-text-field
                    v-model="title"
                    :rules="titleRules"
                    label="Title"
                    required
                  ></v-text-field>
                </div>
              </div> -->
              <!-- <div class="row" style="margin-top: 4%"> -->
              <!-- <div class="col-md-3">
                  <b-form-select
                    v-model="jobType"
                    :options="jobTypesOptions"
                    size="sm"
                    class="mt-3"
                  ></b-form-select>
                </div> -->

              <!-- <div class="col-md-3"> -->
              <!-- <b-form-select
                    v-model="jobFunction"
                    :options="jobFunctionsOptions"
                    size="sm"
                    class="mt-3"
                  ></b-form-select> -->
              <!-- </div> -->

              <!-- <div class="col-md-3">
                  <b-form-select
                    v-model="experience"
                    :options="experienceOptions"
                    size="sm"
                    class="mt-3"
                  ></b-form-select>
                </div> -->
              <!-- <div class="col-md-3"> -->
              <!-- <b-form-select
                    v-model="education"
                    :options="educationOptions"
                    size="sm"
                    class="mt-3"
                  ></b-form-select> -->
              <!-- </div> -->
              <!-- <div class="col-md-3">
                  <b-form-select
                    v-model="other_requirements"
                    :options="otherRequirementsOptions"
                    size="sm"
                    class="mt-3"
                  ></b-form-select>
                </div>
              </div> -->
              <!-- <div class="row" style="margin-top: 5%">
              
                <div
                  class="row"
                  :style="
                  index == 0
                    ? 'margin-top: 8%; '
                    : 'margin-top: 0%; ' + 'padding-left: 1%; padding-right: 1%'
                "
                  v-for="(item, index) in qa"
                  :key="index"
                >
                  <div class="col-md-3">
                    <label>Question</label>
                    <v-text-field
                      v-model="item.question"
                      label="Question"
                    ></v-text-field>
                  </div>
                  <div class="col-md-3" style="margin-top: 1%">
                    <label>Type</label>
                    <b-form-select
                      v-model="item.type"
                      :options="qaTypes"
                      size="sm"
                      class="mt-3"
                    ></b-form-select>
                  </div>
                  
                  <div class="col-md-3" style="margin-top: -0.5%">
                    <label></label>
                    <v-container fluid>
                      <v-combobox
                        v-if="item.type == 'multi'"
                        v-model="item.answer"
                        hide-selected
                        hint="Maximum of 5 tags"
                        label="Options"
                        multiple
                        persistent-hint
                        small-chips
                        :search-input.sync="search"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                Double click to edit & press <kbd>enter</kbd> to
                                create a new one
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>
                    </v-container>
                    <v-checkbox
                      v-model="item.mandatory"
                      label="Mandatory?"
                      required
                    ></v-checkbox>
                  </div>
                  <div class="col-md-3" style="margin-top: 1%">
                    <v-btn
                      class="
                        btn btn-sm btn-success
                        font-weight-bold
                        py-2
                        px-3 px-xxl-5
                        my-1
                      "
                      fab
                      small
                      @click="addNewQuestionRow"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="index > 0"
                      class="
                        btn btn-sm btn-danger
                        font-weight-bold
                        py-2
                        px-3 px-xxl-5
                        my-1
                      "
                      fab
                      small
                      @click="removeQuestionRow(index)"
                    >
                      <v-icon dark>mdi-minus</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div> -->

              <div class="row m-0" style="">
                <div class="col-12 p-0">
                  <div class="py-4 px-4 border_b_silver col-12 ch_hight">
                    <span class="card-label font-weight-bolder Main_Blue"
                      >Description*</span
                    >
                  </div>
                </div>
                <!-- <label>Description</label> -->
                <vue-editor
                  class="mt-4"
                  style="width: 100%"
                  maxLength="6"
                  v-model="description"
                ></vue-editor>
              </div>
              <!-- <div
                class="row"
                style="margin-top: 8%; padding-left: 1%; padding-right: 1%"
              >
                <label>Application Procedure</label>
                <vue-editor
                  style="width: 100%"
                  v-model="applicationProcedure"
                ></vue-editor>
              </div> -->

              <div class="row m-0 p-0 mt-20">
                <div class="col-3 pl-4 p-0">
                  <v-checkbox
                    class="m-0 p-0 checkbox_ch"
                    v-model="job4uPost"
                    label="job4u.pk Network"
                    required
                  ></v-checkbox>
                </div>
                <div class="col-3 p-0">
                  <v-checkbox
                    class="m-0 p-0 checkbox_ch"
                    v-model="myUniPost"
                    label="myUni Network"
                    required
                  ></v-checkbox>
                </div>
                <div class="col-6 p-0">
                  <v-checkbox
                    class="m-0 p-0 checkbox_ch"
                    v-model="show_salary_to_login_users"
                    label="show salary to login users only?"
                    required
                  ></v-checkbox>
                </div>
                <div class="col-md-3 pr-0 border__input_ch pt-0 pb-0">
                  <v-select
                    v-if="myUniPost"
                    v-model="my_uni_selected_universities"
                    :items="universitiesOptions"
                    attach
                    chips
                    label="University to post the job"
                    multiple
                  ></v-select>
                </div>
              </div>
              <div class="pl-4 pb-4" style="">
                <v-btn
                  class="
                    btn btn-sm
                    text-white
                    font-weight-bold
                    py-2
                    px-3 px-xxl-5
                    my-1
                    rounded-0
                    mr-4
                  "
                  style="background: #005696 !important; border-color: #005696"
                  @click="createJob(false)"
                >
                  Publish Job
                </v-btn>
                <v-btn
                  class="
                    btn btn-sm
                    text-white
                    font-weight-bold
                    py-2
                    px-3 px-xxl-5
                    my-1
                    rounded-0
                  "
                  style="background: #64b8eb !important; border-color: #64b8eb"
                  @click="createJob(true)"
                >
                  Save Job as Draft
                </v-btn>
                <v-btn
                  class="
                    btn btn-sm btn-danger
                    font-weight-bold
                    py-2
                    px-3 px-xxl-5
                    my-1
                    ml-4
                    rounded-0
                  "
                  @click="cancel"
                  style="
                    background: #b9e5fb !important;
                    border-color: #b9e5fb !important;
                  "
                >
                  Cancel
                </v-btn>
              </div>
            </v-form>
          </div>
        </v-card>
        <!-- create form end -->
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { API_URL } from "@/core/api";
import Swal from "sweetalert2";
import { VueEditor } from "vue2-editor";
export default {
  data() {
    return {
      jobCondition: [
        { id: 1, feature: 0 },
        { id: 2, spotlight: 0 },
        { id: 3, urgent: 0 },
      ],
      selectedJobCondition: [],
      educationSkill: [{ jobFunction: "", skill: [] }],
      degreeSubjectOptions: [],
      degreeSubject: null,
      min_salary: "",
      max_salary: "",
      salaryRules: [
        (v) => !!v || "Field is required",
        (v) => (!parseInt(v) ? "Field should be number" : ""),
        // (v) => (v && v.length <= 10) || "Title must be less than 25 characters",
      ],
      search: null,
      loading: true,
      success: false,
      token: `Bearer ${localStorage.getItem("token")}`,
      valid: true,
      job4uPost: false,
      myUniPost: false,
      my_uni_selected_universities: null,
      show_salary_to_login_users: false,
      universitiesOptions: [
        { value: "VU", text: "Virtual University of Pakistan" },
        { value: "PU", text: "University of Punjab" },
      ],
      // selection options
      userId:
        localStorage.getItem("user") !== null
          ? JSON.parse(localStorage.getItem("user")).id
          : null,
      title: null,
      city: null,
      jobType: null,
      jobFunction: null,
      experience: null,
      education: null,
      minimumExperience: null,
      genders: null,
      jobShift: null,
      jobShiftsOptions: [],
      require_skills: null,
      skillOptions: [],
      industriesOptions: [],
      noOfVacancies: null,
      applyBy: new Date().toISOString().substr(0, 10),
      description: null,
      applicationProcedure: null,
      qa: [{ question: "", type: null, answer: "", mandatory: false }],
      // data
      cityOptions: [],
      minimumSalariesOptions: [],
      maximumSalariesOptions: [],
      jobTypesOptions: [],
      jobFunctionsOptions: [],
      experienceOptions: [],
      educationOptions: [],
      gendersOptions: [],
      // dynamic options with label on left i.e option 1, option 2
      // cities multiple
      // job type multiple
      // experience multiple
      industry: null,
      qaTypes: [
        { value: null, text: "Select Type" },
        { value: "radio", text: "Options: Yes, No" },
        { value: "multi", text: "Multiple Options" },
        { value: "textarea", text: "Free Text" },
      ],
      titleRules: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length <= 60) || "Title must be less than 60 characters",
      ],
      data: [],
      desiredSalaryOptions: [],
      salary_range: [],
      draft: false,
      benefits: null,
      other_requirements: null,
      delete_after_expiry: false,
      featured: false,
      spotlight: false,
      urgent: false,
    };
  },
  components: {
    VueEditor,
  },
  mounted() {
    this.jobPreRequisite();
    this.getDegreeSubject();
  },
  methods: {
    // changeSkill(item) {
    //   // console.log(item);
    //   // console.log(this.skillOptions);
    //   this.skillOptions = this.skillOptions.filter(
    //     (skill) => parseInt(skill.profession_id) === item.id
    //   );
    //   // console.log(this.skillOptions);
    // },

    addNewRow() {
      this.educationSkill.push({
        jobFunction: "",
        skill: "",
      });
    },
    deleteRow(index) {
      var temp = [];
      this.educationSkill.forEach((element, i) => {
        if (i !== index) temp.push(element);
      });
      this.educationSkill = temp;
    },
    getDegreeSubject() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios.get(`${API_URL}/degree-subject`).then((res) => {
        // this.degreeSubjectOptions = res.data;
        res.data.map((subject) => {
          let temp = {};
          temp.value = subject.subject_name;
          temp.id = subject.id;
          temp.text = subject.subject_name;
          this.degreeSubjectOptions.push(temp);
        });
      });
    },
    addNewQuestionRow() {
      this.qa.push({ question: "", type: null, answer: [], mandatory: false });
    },
    removeQuestionRow(index) {
      var temp = [];
      this.qa.forEach((element, i) => {
        if (i !== index) temp.push(element);
      });
      this.qa = temp;
    },
    cancel() {
      this.$router.push({ name: "recruiter-jobs" });
    },
    jobPreRequisite() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/job-pre-requisite`)
        .then((res) => {
          this.cityOptions = res.data.cities;
          this.minimumSalariesOptions = res.data.minimumSalaries;
          this.minimumSalariesOptions.unshift(
            ...[{ value: null, text: "minimum salary" }]
          );
          this.maximumSalariesOptions = res.data.maximumSalaries;
          this.maximumSalariesOptions.unshift(
            ...[{ value: null, text: "maximum salary" }]
          );
          this.jobTypesOptions = res.data.jobTypes;
          // console.log("JOB FFFFF", res.data.jobFunctions);
          this.jobFunctionsOptions = res.data.jobFunctions;
          this.jobFunctionsOptions.unshift(
            ...[{ value: null, text: "job function" }]
          );
          this.experienceOptions = res.data.experience;
          this.educationOptions = res.data.education;
          this.educationOptions
            .unshift
            // ...[{ value: null, text: "education" }]
            ();
          this.gendersOptions = res.data.genders;
          this.jobShiftsOptions = res.data.jobShifts;
          this.industriesOptions = res.data.industries;
          this.industriesOptions.unshift(
            ...[{ value: null, text: "job industry" }]
          );
          this.benefitsOptions = [
            { value: "Commission", text: "Commission" },
            { value: "Pension", text: "Pension" },
            { value: "Provident Fund", text: "Provident Fund" },
            { value: "Compnay Transport", text: "Compnay Transport" },
            { value: "Car Allowance", text: "Car Allowance" },
            { value: "Housing Allowance", text: "Housing Allowance" },
          ];
          this.otherRequirementsOptions = [
            { value: null, text: "Choose requirements" },
            { value: "Own Conveyance", text: "Own Conveyance" },
            { value: "Own Smartphone", text: "Own Smartphone" },
            { value: "Traveling", text: "Traveling" },
          ];
          this.industriesOptions = res.data.industries;
          this.industriesOptions.unshift(
            ...[{ value: null, text: "job industry" }]
          );
          this.skillOptions = res.data.skills;
          var s = [
            "Upto 20,000",
            "20,001 - 40,000",
            "40,001 - 60,000",
            "60,001 - 80,000",
            "80,001 - 100,000",
            "100,001 - 125,000",
            "125,001 - 150,000",
            "150,001 - 175,000",
            "175,001 - 225,000",
            "225,001 - 275,000",
            "275,001 - 300,000",
            "300,000 - 350,000",
            "350,000 +",
          ];
          s.map((el, i) => {
            this.desiredSalaryOptions.push({
              text: el,
              value: el,
            });
          });
          this.desiredSalaryOptions.unshift(
            ...[{ text: "Any salary", value: "Any salary" }]
          );
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    createJob(draft) {
      if (draft == true) {
        this.draft = true;
      } else {
        this.draft = false;
      }
      if (
        this.title == null ||
        (this.title == "" && this.city == null) ||
        (this.city == "" && this.minimumSalary == null) ||
        (this.minimumSalary == "" && this.maximumSalary == null) ||
        (this.maximumSalary == "" && this.jobType == null) ||
        (this.jobType == "" && this.jobFunction == null) ||
        (this.jobFunction == "" && this.education == null) ||
        (this.education == "" && this.noOfVacancies == null) ||
        // (this.minimumExperience == "" && this.genders == null) ||
        // (this.genders == "" && this.noOfVacancies == null) ||
        // (this.noOfVacancies == "" && this.applyBy == null) ||
        (this.description == "" && this.description == null) ||
        // (this.description == "" && this.applicationProcedure == null) ||
        // (this.applicationProcedure == "" && this.industry == null) ||
        (this.min_salary == "" && this.max_salary == null)
      ) {
        Swal.fire({
          title: "",
          text: "Please, fill all required fields!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      if (this.job4uPost == false && this.myUniPost == false) {
        Swal.fire({
          title: "",
          text: "Please select at-least one platform to submit the job!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      // if (this.degreeSubject == null) {
      //   const degreeSubjct = "";
      // } else {
      //   const degreeSubjct = this.degreeSubject.value;
      // }
      // console.log("ddddddddd", this.degreeSubject);
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .post(`${API_URL}/jobs`, {
          job_title: this.title, //ok
          minimum_experience_years: this.minimumExperience, //ok
          gender: this.genders, //ook
          no_of_vacancies: this.noOfVacancies,
          education: this.education.qualification_level, //ok

          degree_subject: this.degreeSubject == null ? "" : this.degreeSubject,
          apply_by: this.applyBy, //ok
          location_city: this.city, //ok
          delete_after_expiry: this.delete_after_expiry, //ok
          // educationSkill: JSON.stringify(this.educationSkill),
          user_id: this.userId,
          featured: this.featured == true ? 1 : 0,
          spotlight: this.spotlight == true ? 1 : 0,
          urgent: this.urgent == true ? 1 : 0,
          experience: JSON.stringify(this.experience),

          draft: this.draft == true ? 1 : 0,
          benefits: this.benefits,
          other_requirements: this.other_requirements,

          // salary_min: this.salary_range.split(' -')[0],
          salary_min: this.min_salary,
          // salary_max: this.salary_range.split('- ')[1],
          salary_max: this.max_salary,
          job_type: this.jobType,
          job_function: this.educationSkill[0].jobFunction.job_function_name,
          experience: this.experience,

          job_shift: this.jobShift,
          require_skills: this.educationSkill,
          // industry: this.industry,

          job_description: this.description,
          application_procedure: this.applicationProcedure,
          questions: this.qa,
          job4u_post: this.job4uPost == true ? 1 : 0,
          my_uni_post: this.myUniPost == true ? 1 : 0,
          show_salary_to_login_users:
            this.show_salary_to_login_users == true ? 1 : 0,
          delete_after_expiry: this.delete_after_expiry == true ? 1 : 0,
          my_uni_selected_universities: this.my_uni_selected_universities,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          Swal.fire({
            title: "",
            text: "Job posted",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          }).then(function () {
            window.location = "/recruiter/jobs";
          });
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
  },
};
</script>
